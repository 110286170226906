import { graphql, readInlineData } from "react-relay";

import { getAbsoluteUrl } from "~/lib/utils";
import type { helpersUseSharingUrl$key } from "~/queries/__generated__/helpersUseSharingUrl.graphql";

export const computeGaLabelSharingUrl = (reference: helpersUseSharingUrl$key) => {
  const { urlAlias } = readInlineData(
    graphql`
      fragment helpersUseSharingUrl on Content @inline {
        urlAlias
      }
    `,
    reference,
  );

  return { sharingUrl: getAbsoluteUrl(urlAlias) };
};
