import { type SocialShareType, theme, useResponsive } from "@product/scmp-sdk";
import { useAtomValue } from "jotai";
import type { FunctionComponent } from "react";
import { useEffect, useMemo, useState } from "react";

import { checkIsStyleArticle } from "~/components/article/article-render/style-article/helper";
import { computeGaLabelSharingUrl } from "~/components/content/content-share-widget/helpers";
import IconShare from "~/components/header/header-icon/share.svg";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import { currentArticleAtom } from "~/lib/current-item";

import { StyledDynamicContentShareWidget, StyledDynamicDrawer } from "./styles";

export type Variant = "default" | "magazines-style";

const shareWidgetTypesMap: Record<Variant, SocialShareType[]> = {
  default: [
    "facebook",
    "twitter",
    "whatsapp",
    "email",
    "messenger",
    "line",
    "telegram",
    "copy-link",
  ],
  "magazines-style": [
    "facebook",
    "twitter",
    "whatsapp",
    "email1",
    "messenger",
    "line",
    "telegram",
    "copy-link",
  ],
};

export const HeaderContentShareWidgetDrawer: FunctionComponent = () => {
  const currentArticle = useAtomValue(currentArticleAtom);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const isTabletUp = useResponsive(theme.breakpoints.up("tablet"), true);
  useEffect(() => {
    setIsOpenDrawer(false);
  }, [isTabletUp]);

  const variant: Variant = useMemo(() => {
    if (currentArticle && checkIsStyleArticle(currentArticle)) {
      return "magazines-style";
    }
    return "default";
  }, [currentArticle]);

  const shareWidgetTypes = shareWidgetTypesMap[variant];

  if (!currentArticle) return null;
  const { sharingUrl } = computeGaLabelSharingUrl(currentArticle);

  return (
    <>
      <IconShare
        height={16}
        width={16}
        onClick={() => {
          sendGATracking({
            action: "Share menu button/Click",
            category: "Share button (Adhesive)",
            label: sharingUrl,
          });
          setIsOpenDrawer(true);
        }}
      />
      <StyledDynamicDrawer
        $variant={variant}
        anchor="bottom"
        open={isOpenDrawer}
        PaperProps={{
          sx: {
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          },
        }}
        title="Share"
        withClose
        onClose={() => setIsOpenDrawer(false)}
      >
        <StyledDynamicContentShareWidget
          extraProps={{
            copyLink: {
              variant: "label",
            },
          }}
          position="article_adhesive"
          reference={currentArticle}
          types={shareWidgetTypes}
          showLabel
          onClick={() => setTimeout(() => setIsOpenDrawer(false), 1000)}
        />
      </StyledDynamicDrawer>
    </>
  );
};

HeaderContentShareWidgetDrawer.displayName = "HeaderContentShareWidgetDrawer";
