import dynamic from "next/dynamic";

import type { Props as DrawerProps } from "~/components/common/drawer";
import type { Props as ContentShareWidgetProps } from "~/components/content/content-share-widget";

export const DynamicDrawer = dynamic<DrawerProps>(() =>
  import("~/components/common/drawer").then(module => module.Drawer),
);

export const DynamicContentShareWidget = dynamic<ContentShareWidgetProps>(() =>
  import("~/components/content/content-share-widget").then(module => module.ContentShareWidget),
);
