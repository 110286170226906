import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontBarlow, fontBarlowSemiCondensed, transientOptions } from "@product/scmp-sdk";

import { DrawerCard, DrawerCardHeader, DrawerCardTitle } from "~/components/common/drawer/styles";

import { DynamicContentShareWidget, DynamicDrawer } from "./dynamics";

export const StyledDynamicContentShareWidget = styled(DynamicContentShareWidget)`
  display: grid;
  grid-template-columns: repeat(4, 50px);
  gap: 24px 37px;
  justify-content: center;
  justify-items: center;
`;

export type DrawerProps = {
  $variant?: "default" | "magazines-style";
};
export const StyledDynamicDrawer = styled(DynamicDrawer, { ...transientOptions })<DrawerProps>`
  ${DrawerCard} {
    position: relative;

    inline-size: 100%;
    padding-block: 20px 32px;
    padding-inline: 20px;
  }

  ${DrawerCardHeader} {
    margin-block-end: 25px;
  }

  ${DrawerCardTitle} {
    inline-size: 100%;
    margin-inline-start: 16px;

    font-weight: unset;
    font-size: 19px;
    line-height: 23px;
    text-align: center;

    ${props =>
      props.$variant === "magazines-style" &&
      css`
        color: #222222;
        font-weight: 400;
        font-size: 18px;
        font-family: ${fontBarlow};
        line-height: 21.6px;
        text-transform: uppercase;
      `}
  }

  ${StyledDynamicContentShareWidget} {
    ${props =>
      props.$variant === "magazines-style" &&
      css`
        button {
          > :first-child {
            border: 1px solid #222222;

            background-color: #ffffff;

            svg {
              path {
                fill: #222222;
              }
            }
          }

          > :last-child {
            color: #222222;
            font-weight: 500;
            font-size: 16px;
            font-family: ${fontBarlowSemiCondensed};
            line-height: 16.8px;
            letter-spacing: -0.1px;
          }
        }
      `}
  }
`;
