import { useAtomValue } from "jotai";
import { type FunctionComponent, useMemo } from "react";
import { graphql, readInlineData } from "react-relay";

import { checkIsStyleArticle } from "~/components/article/article-render/style-article/helper";
import { currentArticleAtom } from "~/lib/current-item";
import type { headerContentBookmarkArticle$key } from "~/queries/__generated__/headerContentBookmarkArticle.graphql";

import { StyledContentBookmark } from "./styles";

export type Variant = "default" | "magazines-style";

export const HeaderContentBookmark: FunctionComponent = () => {
  const currentArticle = useAtomValue(currentArticleAtom);

  const variant: Variant = useMemo(() => {
    if (currentArticle && checkIsStyleArticle(currentArticle)) {
      return "magazines-style";
    }
    return "default";
  }, [currentArticle]);

  if (!currentArticle) return null;

  const data = readInlineData<headerContentBookmarkArticle$key>(
    graphql`
      fragment headerContentBookmarkArticle on Article @inline {
        entityId
        urlAlias
      }
    `,
    currentArticle,
  );

  return (
    <StyledContentBookmark $variant={variant} entityId={data.entityId} urlAlias={data.urlAlias} />
  );
};

HeaderContentBookmark.displayName = "HeaderContentBookmark";
