import { useAtomValue } from "jotai";
import type { FunctionComponent } from "react";
import { graphql, readInlineData } from "react-relay";

import { currentArticleAtom } from "~/lib/current-item";
import type { headerContentCommentTrigger$key } from "~/queries/__generated__/headerContentCommentTrigger.graphql";

import { StyledArticleCommentsTrigger } from "./styles";

export const HeaderContentCommentTrigger: FunctionComponent = () => {
  const currentArticle = useAtomValue(currentArticleAtom);
  if (!currentArticle) return null;

  const article = readInlineData<headerContentCommentTrigger$key>(
    graphql`
      fragment headerContentCommentTrigger on Article @inline {
        ...articleCommentsTriggerArticle
      }
    `,
    currentArticle,
  );

  return <StyledArticleCommentsTrigger location="Adhesive" reference={article} variant="button" />;
};

HeaderContentCommentTrigger.displayName = "HeaderContentCommentTrigger";
