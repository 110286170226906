/**
 * @generated SignedSource<<37b09486513c1daffff2b9d63b1114c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type headerContentBookmarkArticle$data = {
  readonly entityId: string;
  readonly urlAlias: string;
  readonly " $fragmentType": "headerContentBookmarkArticle";
};
export type headerContentBookmarkArticle$key = {
  readonly " $data"?: headerContentBookmarkArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"headerContentBookmarkArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "headerContentBookmarkArticle"
};

(node as any).hash = "01714711555bd0c3559d4d24e228cb60";

export default node;
